<template>
    <v-card width="100%" class="mx-auto">
        <v-card-title>Search by Order Number</v-card-title>
        <v-alert
            v-show="error != ''"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
        >
            {{ error }}
        </v-alert>

        <v-form>
            <v-card-text>
                <v-text-field
                    label="Order Number"
                    v-on:keypress="isNumber($event)"
                    v-model.trim="orderNumber"
                    :rules="[rules.required]"
                />
            </v-card-text>
            <v-card-actions class="px-4 py-4">
                <v-btn
                    color="primary"
                    type="submit"
                    @click.prevent="searchOrder"
                    >Search</v-btn
                >
                <v-btn
                    color="primary"
                    type="button"
                    @click.prevent="reassignNewCartId"
                    >Assign New Cart ID</v-btn
                >
                <v-btn
                    color="primary"
                    type="button"
                    @click.prevent="showCustomerServiceNotePopup"
                    >Add Customer Service Note</v-btn
                >
            </v-card-actions>
        </v-form>

        <v-dialog v-model="customerServiceNotePopup" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5"
                        >Add Customer Service Note to #{{ orderNumber }}</span
                    >
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    outlined
                                    :rules="[rules.required]"
                                    label=""
                                    v-model="customerServiceNote"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        type="button"
                        @click="customerServiceNotePopup = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        type="button"
                        @click="saveCustomerServiceNote"
                    >
                        Save Note
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import * as data from '@/data/index';
export default {
    name: 'Search',
    data() {
        return {
            orderNumber: '',
            rules: {
                required: value => !!value || 'The field is required.'
            },
            error: '',
            searchedOrder: {},
            customerServiceNotePopup: false,
            customerServiceNote: ''
        };
    },
    methods: {
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if ((charCode >= 48 && charCode <= 57) || charCode === 13) {
                // 13 is for enter key
                return true;
            }
            evt.preventDefault();
        },

        async searchOrder() {
            let self = this;
            const order = await this.getSearchedOrder();
            if (!order || !order.order) {
                return false;
            }
            this.$store.dispatch('assignCurrentOrder', order.order.id).then(
                () => {
                    this.$router.push({
                        name: 'Order',
                        params: {
                            order_type: '',
                            show_next_order_button: false,
                            show_alert: false,
                            alert_message: ''
                        }
                    });
                    self.$root.$dialogLoader.hide();
                },
                error => {
                    console.log(error);
                    this.error = 'There was an error getting the order';
                    self.$root.$dialogLoader.hide();
                }
            );
        },

        async reassignNewCartId() {
            const order = await this.getSearchedOrder();
            if (!order || !order.order) {
                return false;
            }
            if (order && order.is_locked && order.is_locked === true) {
                this.error = 'Order is locked!';
                this.$root.$dialogLoader.hide();
                return false;
            }
            this.$router.push({
                name: 'ReassignCartId',
                params: { order_id: order.order.id }
            });
            this.$root.$dialogLoader.hide();
        },

        async showCustomerServiceNotePopup() {
            const order = await this.getSearchedOrder();
            if (!order || !order.order) {
                return false;
            }
            if (order && order.is_locked && order.is_locked === true) {
                this.error = 'Order is locked!';
                this.$root.$dialogLoader.hide();
                return false;
            }
            this.searchedOrder = order;
            this.$root.$dialogLoader.hide();
            this.customerServiceNotePopup = true;
        },

        async saveCustomerServiceNote() {
            let self = this;
            self.$store
                .dispatch('updateOrder', {
                    orderId: self.searchedOrder.order.id,
                    data: {
                        customer_service_note: self.customerServiceNote
                    }
                })
                .then(() => {
                    self.customerServiceNotePopup = false;
                })
                .catch(() => {
                    self.error =
                        'There was an error saving customer service note to the order';
                    self.customerServiceNotePopup = false;
                });
        },

        async getSearchedOrder() {
            let self = this;
            self.error = '';
            self.$root.$dialogLoader.show('Searching for order...', {});
            const order = await data.orders
                .getOrder(this.orderNumber)
                .then(querySnapshot => {
                    const order = querySnapshot.data();
                    if (order && order.order && order.order.id) {
                        return order;
                    } else {
                        this.error = 'Order not found!';
                        self.$root.$dialogLoader.hide();
                        return {};
                    }
                })
                .catch(function(error) {
                    console.error(error);
                    self.error = 'There was an error getting the order';
                    self.$root.$dialogLoader.hide();
                });
            return order;
        }
    }
};
</script>
