<template>
    <v-row no-gutters align="center">
        <v-col>
            <v-card elevation="3" v-if="product">
                <v-card-text class="text-center">
                    <h3>
                        {{ product.name }}
                    </h3>
                    <v-img
                        v-if="productImages.length > 0"
                        class="ma-auto mt-2 mb-4 item-image"
                        :src="productImages[0].url_standard"
                        max-width="200"
                        max-height="200"
                        contain
                    />
                    <v-row>
                        <v-col>
                            <h3>Bin Picking Number</h3>
                            <h4 v-if="product.bin_picking_number">
                                {{ product.bin_picking_number }}
                            </h4>
                            <h4 v-else>Unknown</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-simple-table class="text-left fullwidth" dense s>
                            <template v-slot:default>
                                <tbody>
                                    <tr v-if="product.sku">
                                        <th>SKU</th>
                                        <td>
                                            {{ product.sku }}
                                        </td>
                                    </tr>
                                    <tr v-if="product.location">
                                        <th>Location</th>
                                        <td>{{ product.location }}</td>
                                    </tr>
                                    <tr v-if="product.upc">
                                        <th>UPC</th>
                                        <td>
                                            {{ product.upc }}
                                        </td>
                                    </tr>
                                    <tr v-if="product.price">
                                        <th>Price</th>
                                        <td>${{ product.price }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-row class='py-6'>
                <v-col>
                    <v-btn
                        block
                        color="secondary"
                        dark
                        :disabled="!getProductUrl()"
                        elevation="0"
                        :href="getProductUrl()"
                        target="_blank"
                    >
                       View Product in Store
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        block
                        color="primary"
                        dark
                        elevation="0"
                        @click="backToScan"
                    >
                        Scan Another
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import * as data from '@/data/index';
import * as Config from '@/data/config'

export default {
    name: 'Product',
    computed: {
        productId() {
            return this.$route.params.id;
        }
    },
    data: () => {
        return {
            product: null,
            productImages: [],
            storeUrl: null
        };
    },
    mounted: function () {
        this.$root.$dialogLoader.show('Loading the product data…', {});
        const self = this;

        data.products.getProductImages(this.productId).then(
            (result) => {
                if ('data' in result && result.data) {
                    this.productImages = result.data.sort((i1, i2) => i1.sort_order < i2.sort_order ? -1 : 1)
                }
            },
            (error) => {
                console.log(error);
            }
        );

        data.products.getProductById(this.productId).then(
            (result) => {
                if ('data' in result && result.data) {
                    this.product = result.data;
                } else {
                    this.error = "We can't find a matching product.";
                }
                self.$root.$dialogLoader.hide();
            },
            (error) => {
                console.log(error);
                self.$root.$dialogLoader.hide();
            }
        );
    
        Config.getStoreUrl().then((url) => {
            this.storeUrl = url
        })
    },
    methods: {
        getProductUrl () {
            return (this.storeUrl ? this.storeUrl : '') + (this.product && this.product.custom_url ? this.product.custom_url.url : '')
        },
        backToScan () {
          this.$router.push('/product')
        }
    }
};
</script>
