<template>
    <v-card elevation="0" class="mx-auto text-center" width="100%">
        <v-card elevation="0" class="mt-12">
            <h1 class="justify-center">Scan Product Barcode</h1>
            <v-icon color="secondary lighten-1" size="64px">fa-barcode</v-icon>
        </v-card>
        <v-alert
            v-show="error != ''"
            color="secondary"
            border="left"
            colored-border
            type="error"
            elevation="2"
            class="mt-6"
        >
            {{ error }}
        </v-alert>

        <ScannableInput
            :active="true"
            :debug="false"
            @scan="scan"
        ></ScannableInput>
    </v-card>
</template>

<script>
import * as data from '@/data/index';
import ScannableInput from '@/components/ScannableInput';

export default {
    name: 'Scan',
    data() {
        return {
            error: ''
        };
    },
    components: {
        ScannableInput
    },
    methods: {
        scan(response) {
            let self = this;
            this.$root.$dialogLoader.show('Finding the product…', {});

            data.products.getProductByUpc(response.input).then(
                result => {
                    if ('data' in result && result.data.length > 0) {
                        this.$router.push({
                            name: 'Product',
                            params: { id: result.data[0].id }
                        });
                    } else {
                        this.error =
                            "We can't find a product matching the barcode.";
                    }
                    self.$root.$dialogLoader.hide();
                },
                error => {
                    console.log(error);
                    self.$root.$dialogLoader.hide();
                }
            );
        }
    }
};
</script>
